/**
 * Adds an event listener.
 * @param {string} type - The type of the EventListener.
 * @param {function} listenerFunction - The function of the listener.
 */
export function addListener(type, listenerFunction) {
  document.addEventListener(type, listenerFunction)
}

/**
 * Removes an event listener.
 * @param {string} type - The type of the EventListener.
 * @param {function} listenerFunction - The function of the listener.
 */
export function removeListener(type, listenerFunction) {
  document.removeEventListener(type, listenerFunction)
}

// TODO: To be updated.
export class ListenerHelper {
  constructor(type, listenerFunction) {
    this.type = type
    this.listenerFunction = listenerFunction
    this.addListener()
  }

  addListener() {
    document.addEventListener(this.type, this.listenerFunction)
  }

  removeListener() {
    document.removeEventListener(this.type, this.listenerFunction)
  }

  static create(type, listenerFunction) {
    return new ListenerHelper(type, listenerFunction)
  }
}
