<template>
  <div class="content-container">
    <PageTitle :title="$t('MY_SCHOOL.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('EDIT_TEACHER.TITLE') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_TEACHER_PORTAL.MY_SCHOOL"/>

    <!-- Form -->
    <FormWrapper v-if="teacherDetails" :send-form-data="validateData" class="edit-form">
      <template #form-content>

        <!-- Name -->
        <InputField :field-title="$t('NAME')"
                    :field-name="'name'"
                    :rules="'required'"
                    :type="'text'"
                    :value="teacherDetails.name"
                    :placeholder="$t('NAME')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :input-icon="require('../../../assets/icons/icn_account.svg')"
                    :api-error-text="''"
                    class="form-element"/>

        <!-- Email -->
        <InputField :field-title="$t('EMAIL_ADDRESS')"
                    :field-name="'email'"
                    :value="teacherDetails.email"
                    :rules="'required'"
                    :type="'email'"
                    :placeholder="$t('EMAIL_ADDRESS')"
                    :cy-selector="'email-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :input-icon="require('../../../assets/icons/icn_email.svg')"
                    :api-error-text="''"
                    class="form-element"/>


        <DropdownWithCheckboxes id="classes"
                                :field-title="$t('EDIT_TEACHER.LINKED_CLASSES')"
                                :dropdown-options="schoolClasses"
                                :placeholder="$t('EDIT_CLASS.TEACHERS_PLACEHOLDER')"
                                :field-name="'classes'"
                                :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                                :input-icon="require('../../../assets/icons/icn_class.svg')"
                                :callback="newClassSelection"
                                :api-error-text="''"
                                :rules="''"
                                :selected-options="startSelected"
                                class="form-element"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button edit-button">
          {{ $t('SAVE_CHANGES') }}
        </button>
      </template>

    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import DropdownWithCheckboxes from '@/components/yo-form/DropdownWithCheckboxes'
import { GET_TEACHER_REQUESTS, GET_SCHOOL_CLASSES, EDIT_TEACHER } from '@/store/modules/teacherPortal/actions'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref, watch } from '@vue/runtime-core'
export default {
  name: 'EditTeacher',
  components: {
    FormWrapper,
    BackButton,
    PageTitle,
    InputField,
    DropdownWithCheckboxes,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    //Get Id of edited
    const teacherId = route.params.teacherId


    store.dispatch(GET_TEACHER_REQUESTS)
    store.dispatch(GET_SCHOOL_CLASSES)

    const schoolClasses = computed(() => store.getters.getSchoolClasses)
    const requests = computed(() => store.getters.getTeacherRequests)
    const startSelected = computed(() => schoolClasses.value.filter(value => value.teachers.some(teacher => teacher.id === parseInt(teacherId))).map(value => value.id))

    const teacherDetails = ref()


    watch(() => requests.value,
      (curr, prev) => {
        if (curr.approvedTeachers) {
          teacherDetails.value = curr.approvedTeachers.find(x => x.id.toString() === teacherId.toString())
        }
      })


    const finalSelected = ref(startSelected.value)
    function newClassSelection(data) {
      finalSelected.value = data
    }


    /** Form **/
    function validateData(data) {
      data.classes = finalSelected.value.map((value) => value.id)
      data.id = teacherId
      editTeacher(data)
    }

    function editTeacher(payload) {
      store.dispatch(EDIT_TEACHER, payload)
    }

    return {
      ROUTE_NAMES_TEACHER_PORTAL,
      teacherDetails,
      schoolClasses,
      /** Form **/
      validateData,
      newClassSelection,
      startSelected,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.edit-form {
  margin-top: rem(32);
  width: rem(492);

  .form-element {
    &:not(:last-of-type) {
      margin-bottom: rem(28);
    }
  }
}

.edit-button {
  margin-top: rem(48);
}
</style>
